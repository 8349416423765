import React from 'react';
import { withStyles } from '@material-ui/styles';
import { resume } from '../content';
import styles from '../styles/navbarStyles';

const Navbar = ({ classes }) => {
  const { navbar, brand, navLinks, link } = classes;

  return (
    <nav className={navbar}>
      <div className={brand}>cm</div>
      <ul className={navLinks}>
        <li className={`${link} about`}><a href="#about">about</a></li>
        <li className={`${link} resume`}><a href={resume} target="_blank" rel="noopener noreferrer">resume</a></li>
        <li className={`${link} skills`}><a href="#skills">skills</a></li>
        <li className={`${link} projects`}><a href="#projects">projects</a></li>
        <li className={`${link} contact`}><a href="#contact">contact</a></li>
      </ul>
    </nav>
  );
};

export default withStyles(styles)(Navbar);
