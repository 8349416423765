const colors = {
  white: "#ffffff",
  darkGrey: "#323336",
  black: "#000000",

  red: "#e01e5a",
  blue: "#36c5f0",
  green: "#2eb67d",
  lightgreen: "#33cf8e",
  yellow: "#ecb22e",
  purple: "#4a154b",
};

export default colors;
