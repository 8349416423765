const resume =
  "https://drive.google.com/file/d/1MgFbvhWgNVoTxVtUGkiwP9eR_BLElxkF/view?usp=sharing";

const skillIcons = [
  "react-original-wordmark",
  "javascript-plain",
  "sass-original",
  "nodejs-plain-wordmark",
  "html5-plain-wordmark",
  "css3-plain-wordmark",
  "postgresql-plain-wordmark",
  "express-original-wordmark",
  "git-plain-wordmark",
];

const projectsInfo = [
  {
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-cm.appspot.com/o/weather.png?alt=media&token=c202741a-ff4c-4540-870e-3da62d3c87b9",
    name: "Weather App",
    desc: "A simple weather app",
    github: "https://github.com/Ceejaymar/weather",
    live: "https://starlit-queijadas-1645a0.netlify.app/",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-cm.appspot.com/o/TheEnds.jpg?alt=media&token=158a2289-3f03-4682-b51d-d56ea7d468e9",
    name: "The Ends homepage",
    desc: "A marketplace of items that make you feel where you belong. React, Node, SCSS & Postgres.",
    github: "https://github.com/Ceejaymar/TheEnds-ecommerce-frontend",
    live: "https://theends.web.app/",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-cm.appspot.com/o/color-app.jpg?alt=media&token=8a0e8a8f-f74d-4491-a130-c7678c2eef4b",
    name: "Color app",
    desc: "A website that lets you create color palettes. Built using React & MaterialUI & Colt Steele's React course.",
    github: "https://github.com/Ceejaymar/color-pallette-app",
    live: "https://mystifying-wiles-bc31c7.netlify.com/",
  },
  // {
  //   img: "https://firebasestorage.googleapis.com/v0/b/portfolio-cm.appspot.com/o/Dreamshare.jpg?alt=media&token=99e96356-9131-4345-9cd4-7eef06fa7dfd",
  //   name: "Dreamshare homepage",
  //   desc: "",
  //   github: "https://github.com/Ceejaymar/Dreamshare-morty",
  //   live: "http://dreamshare-cm.surge.sh",
  // },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-cm.appspot.com/o/KnobcreekLow.jpg?alt=media&token=838c900d-8f8c-446d-bb20-a539905e0cbf",
    name: "Knobcreek homepage",
    desc: "",
    github: "https://github.com/Ceejaymar/knobcreek-barrel",
    live: "http://knobcreek-cm.surge.sh/",
  },
  // {
  //   img: "https://firebasestorage.googleapis.com/v0/b/portfolio-cm.appspot.com/o/ReactorLow.jpg?alt=media&token=323df8ac-5d13-4983-9be8-a147e1d1beb8",
  //   name: "Reactor homepage",
  //   desc: "",
  //   github: "https://github.com/Ceejaymar/ap-reactor-test",
  //   live: "http://reactor-cm.surge.sh",
  // },
  // {
  //   img: "https://firebasestorage.googleapis.com/v0/b/portfolio-cm.appspot.com/o/Trillo.jpg?alt=media&token=de607792-b343-4bc9-8925-9eafb03797b9",
  //   name: "Trillo homepage",
  //   desc: "Trillo static website built using HTML, SASS, Flexbox",
  //   github: "https://github.com/Ceejaymar/trillo-practice",
  //   live: "http://trillo-carmar.surge.sh/",
  // },
  // {
  //   img: 'https://firebasestorage.googleapis.com/v0/b/portfolio-cm.appspot.com/o/Bitly.jpg?alt=media&token=d26d9fae-34c5-4231-a267-bea62e507da8',
  //   name: 'Bitly clone',
  //   desc: 'URL shortener made using Bitly\'s SDK.',
  //   github: 'https://github.com/Ceejaymar/Bitly-clone',
  //   live: 'http://bitly-clone.surge.sh/'
  // },
  // {
  //   img: 'https://firebasestorage.googleapis.com/v0/b/portfolio-cm.appspot.com/o/Critiq.jpg?alt=media&token=eeaab033-3954-49cf-96ad-88a7f222bc2a',
  //   name: ' homepage',
  //   desc: 'Critiq is a peer review app that allows writers to upload their work and receive feedback. Critiq adopts a similar functionality as Google Docs but affords more peer to peer editing capabilities.',
  //   github: 'https://github.com/gilruben/critiQ',
  //   live: 'https://litclub.herokuapp.com'
  // },
  // {
  //   // img: 'http://i.imgur.com/PQuRsal.png',
  //   img: 'https://firebasestorage.googleapis.com/v0/b/portfolio-cm.appspot.com/o/Karmapoint.jpg?alt=media&token=fc2bb922-161f-4339-a1c7-0f06af6af1fe',
  //   name: 'Karmapoint homepage',
  //   desc: 'KarmaPoint is a platform that allows young adults to find, track and verify volunteer hours.',
  //   github: 'https://github.com/bethea28/karmapoint',
  //   live: ''
  // }
];

export { resume, skillIcons, projectsInfo };
