import colors from "./colors";
import sizes from "./sizes";

const styles = {
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "70px",
    padding: "0 40px",
    borderBottom: "1px solid lightgrey",
    [sizes.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
    },
  },
  brand: {
    display: "flex",
    alignItems: "center",
    fontSize: "2rem",
    textTransform: "uppercase",
  },
  navLinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    margin: "0",
  },
  link: {
    listStyle: "none",
    fontSize: "1.5rem",
    marginRight: "15px",
    padding: "7px 0",
    position: "relative",
    overflow: "hidden",
    "& a": {
      textDecoration: "none",
      color: "black",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      left: "0",
      backgroundColor: "black",
      width: "100%",
      height: "3px",
      zIndex: "-1",
      transform: "translateX(-101%)",
      transition: "transform .3s ease-in-out",
    },
    "&:hover": {
      "&:after": {
        transform: "translateX(0)",
      },
    },
    "&.about:after": {
      backgroundColor: colors.red,
    },
    "&.resume:after": {
      backgroundColor: colors.purple,
    },
    "&.skills:after": {
      backgroundColor: colors.green,
    },
    "&.projects:after": {
      backgroundColor: colors.blue,
    },
    "&.contact:after": {
      backgroundColor: colors.yellow,
    },
    [sizes.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
};

export default styles;
