import React from 'react';
import { withStyles } from '@material-ui/styles';
import styles from '../styles/footerStyles';

const Footer = ({ classes }) => {
  const { message, footer } = classes;

  return (
    <div>
      <div className={message}>
        <p>Let's create some cool things!</p>
      </div>
      <footer className={footer}>
        <small>&copy; Carlos Martinez</small>
        <p>Thank you for visiting!</p>
      </footer>
    </div>
  );
};

export default withStyles(styles)(Footer);
