import colors from "./colors";
import sizes from "./sizes";

const styles = {
  skills: {
    display: "flex",
    height: "90vh",
    [sizes.down("lg")]: {
      height: "auto",
      flexDirection: "column",
    },
  },
  skillsLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    height: "100%",
    backgroundColor: colors.green,
    [sizes.down("lg")]: {
      width: "100%",
    },
  },
  skillsHeader: {
    color: colors.white,
    fontSize: "3rem",
    width: "500px",
    [sizes.down("lg")]: {
      fontSize: "2rem",
      width: "550px",
    },
    [sizes.down("sm")]: {
      fontSize: "1.8rem",
      textAlign: "center",
    },
    [sizes.down("xs")]: {
      fontSize: "1.4rem",
      textAlign: "center",
      padding: "10px",
    },
  },
  skillsRight: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    height: "100%",
    [sizes.down("lg")]: {
      width: "100%",
      height: "400px",
      padding: "40px 0",
    },
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    width: "33.3333%",
    fontSize: "6rem",
    [sizes.down("lg")]: {
      fontSize: "5rem",
    },
  },
};

export default styles;
