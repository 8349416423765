import colors from "./colors";
import sizes from "./sizes";

const styles = {
  contact: {
    display: "flex",
    height: "90vh",
    [sizes.down("lg")]: {
      height: "auto",
      flexDirection: "column-reverse",
    },
  },
  contactLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "50%",
    [sizes.down("lg")]: {
      width: "100%",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "45%",
    height: "35%",
    [sizes.down("lg")]: {
      height: "300px",
    },
    [sizes.down("xs")]: {
      height: "250px",
    },
  },
  input: {
    fontSize: "1.2rem",
    fontFamily: "Source Sans Pro, sans-serif",
    paddingLeft: "10px",
    width: "100%",
    [sizes.down("xs")]: {
      fontSize: "1rem",
    },
  },
  message: {
    fontSize: "1.2rem",
    fontFamily: "Source Sans Pro, sans-serif",
    paddingLeft: "10px",
    width: "100%",
    height: "50%",
    [sizes.down("xs")]: {
      fontSize: "1rem",
    },
  },
  submitButton: {
    width: "50%",
    fontSize: "1.3rem",
    fontFamily: "Source Sans Pro, sans-serif",
    padding: "10px 5px 10px 5px",
    cursor: "pointer",
    backgroundColor: colors.green,
    color: colors.white,
    border: "none",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.25)",
    transition: ".5s",
    "&:hover": {
      backgroundColor: colors.lightgreen,
      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.35)",
      transform: "translateY(-2px)",
    },
    [sizes.down("lg")]: {
      width: "45%",
    },
    [sizes.down("sm")]: {
      fontSize: "1rem",
      width: "45%",
    },
    [sizes.down("xs")]: {
      fontSize: ".7rem",
      width: "55%",
    },
  },
  contactHeader: {
    fontSize: "3rem",
    [sizes.down("lg")]: {
      fontSize: "2rem",
    },
    [sizes.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  contactRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    backgroundColor: colors.yellow,
    [sizes.down("lg")]: {
      width: "100%",
    },
  },
  icons: {
    [sizes.down("lg")]: {
      margin: "60px 0 40px 0",
    },
    [sizes.down("xs")]: {
      margin: "40px 0 30px 0",
    },
  },
  contactLink: {
    "&:link, &:visited": {
      color: "black",
    },
  },
  icon: {
    fontSize: "5rem",
    margin: "10px",
    transition: ".4s",
    "&:hover": {
      transform: "scale(1.1)",
    },
    "&.github:hover": {
      color: "#242124",
    },
    "&.linkedin:hover": {
      color: "#0077B5",
    },
    "&.twitter:hover": {
      color: "#55ACEE",
    },
    "&.instagram:hover": {
      color: "#DD2A7B",
    },
    [sizes.down("sm")]: {
      fontSize: "3rem",
    },
    [sizes.down("sm")]: {
      fontSize: "2.5rem",
    },
  },
  contactPara: {
    fontSize: "2.5rem",
    width: "50%",
    [sizes.down("lg")]: {
      fontSize: "2rem",
      width: "60%",
    },
  },
  email: {
    display: "inline-block",
    fontSize: "2.5rem",
    fontWeight: "600",
    textDecoration: "none",
    paddingBottom: "10px",
    "&:link, &:visited": {
      color: colors.black,
    },
    position: "relative",
    overflow: "hidden",
    "& a": {
      textDecoration: "none",
      color: "black",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      left: "0",
      backgroundColor: "black",
      width: "100%",
      height: "3px",
      transform: "translateX(-100%)",
      transition: "transform .3s ease-in-out",
    },
    "&:hover": {
      "&:after": {
        transform: "translateX(0)",
      },
    },
    [sizes.down("lg")]: {
      fontSize: "2rem",
      padding: "0",
      // marginTop: '10px'
    },
    [sizes.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
};

export default styles;
