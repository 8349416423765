import colors from "./colors";
import sizes from "./sizes";

const styles = {
  projects: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.blue,
    color: colors.white,
    paddingBottom: "100px",
    [sizes.down("sm")]: {
      paddingBottom: "50px",
    },
  },
  projectsHeader: {
    fontSize: "3rem",
    [sizes.down("lg")]: {
      fontSize: "2rem",
    },
    [sizes.down("sm")]: {
      fontSize: "1.8rem",
    },
    [sizes.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  projectList: {
    width: "1280px",
    display: "flex",
    flexWrap: "wrap",
    [sizes.down("lg")]: {
      width: "960px",
    },
    [sizes.down("md")]: {
      width: "740px",
    },
    [sizes.down("sm")]: {
      width: "550px",
    },
    [sizes.down("xs")]: {
      width: "350px",
    },
  },
  projectCont: {
    width: "50%",
    position: "relative",
    [sizes.down("sm")]: {
      width: "100%",
      margin: "5px 0",
    },
  },
  image: {
    width: "100%",
    height: "100%",
  },
  overlay: {
    backgroundColor: "rgba(57, 57, 57, .9)",
    // backgroundImage: 'linear-gradient(to bottom, transparent, transparent,rgba(57, 57, 57, .9))',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    transition: "opacity .3s ease-in-out",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      // backgroundImage: 'linear-gradient(to bottom, rgba(57, 57, 57, .9), rgba(57, 57, 57, .9))',
      opacity: 1,
    },
  },
  description: {
    color: "white",
    fontSize: "20px",
    fontWeight: "600",
    position: "absolute",
    width: "65%",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  linkGroup: {
    position: "absolute",
    bottom: "30px",
  },
  github: {
    margin: "0 30px",
    color: "transparent",
    backgroundColor: "transparent",
    // transition: 'all .3s ease-in-out',
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    fontSize: "1.5rem",
    fontWeight: "500",
    padding: "0 10px 10px 10px",
    "&:before": {
      content: '"github"',
      backgroundColor: colors.red,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      transition: "all .3s ease-in-out",
      color: colors.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
    "&:after": {
      content: '"github"',
      backgroundColor: colors.blue,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      transition: "all .3s ease-in",
      transform: "translate(-100%, 100%)",
      color: colors.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
    "&:hover:before": {
      transform: "translate(100%, -100%)",
    },
    "&:hover:after": {
      transform: "translate(0)",
    },
    "&:hover": {
      // borderBottom: `3px solid ${colors.green}`,
    },
  },
  live: {
    margin: "0 30px",
    color: "transparent",
    backgroundColor: "transparent",
    // transition: 'all .3s ease-in-out',
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    fontSize: "1.5rem",
    fontWeight: "500",
    padding: "0 10px 10px 10px",
    "&:before": {
      content: '"live"',
      backgroundColor: colors.red,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      transition: "all .3s ease-in-out",
      color: colors.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
    "&:after": {
      content: '"live"',
      backgroundColor: colors.blue,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      transition: "all .3s ease-in",
      transform: "translate(-100%, 100%)",
      color: colors.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
    "&:hover:before": {
      transform: "translate(100%, -100%)",
    },
    "&:hover:after": {
      transform: "translate(0)",
    },
    "&:hover": {
      // borderBottom: `3px solid ${colors.green}`,
    },
  },
};

export default styles;
