import React from 'react';
import { withStyles } from '@material-ui/styles';
import { skillIcons } from '../content';
import styles from '../styles/skillsStyles';

const Skills = ({ classes }) => {
  const { skills, skillsLeft, skillsHeader, skillsRight, icon } = classes;

  return (
    <section className={skills} id='skills'>
      <div className={skillsLeft}>
        <h2 className={skillsHeader}>Some of the tools I like to work with</h2>
      </div>
      <div className={skillsRight}>
        {
          skillIcons.map((skill, key) => (
            <i key={key} className={`devicon-${skill} colored ${icon}`} />
          ))
        }
      </div>
    </section>
  );
};

export default withStyles(styles)(Skills);
