import React from 'react';
import Icon from '@material-ui/core/Icon';
import { loadCSS } from 'fg-loadcss';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/contactStyles';
import '../styles/mailToUI.css';

const Contact = ({ classes }) => {
  const {
    contact,
    contactLeft,
    email,
    form,
    input,
    message,
    submitButton,
    contactHeader,
    contactRight,
    icons,
    contactLink,
    icon,
    contactPara
  } = classes;

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
  }, []);

  return (
    <section className={contact} id='contact'>
      <div className={contactLeft}>
        <h2 className={contactHeader}>Get in touch</h2>
        <form
          action="https://formspree.io/mpzdqwqw"
          method="POST"
          className={form}
        >
          {/* <label> */}
            {/* Your email: */}
            <input className={input} type="text" name="_replyto" placeholder="Your email" />
          {/* </label> */}
          {/* <label> */}
            {/* Your message: */}
            <textarea className={message} name="message" placeholder="Write a message" />
          {/* </label> */}
          <button className={submitButton} type="submit">Send message</button>
        </form>
        <div className={icons}>
          <a
            href="https://www.linkedin.com/in/carmart/"
            className={contactLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon className={`fab fa-linkedin ${icon} linkedin`} />
          </a>
          <a
            href="https://github.com/Ceejaymar"
            className={contactLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon className={`fab fa-github-square ${icon} github`} />
          </a>
          <a
            href="https://twitter.com/steadyonthego"
            className={contactLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon className={`fab fa-twitter-square ${icon} twitter`} />
          </a>
          <a
            href="https://www.instagram.com/steadyonthego/"
            className={contactLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon className={`fab fa-instagram ${icon} instagram`} />
          </a>
        </div>
      </div>
      <div className={contactRight}>
        <p className={contactPara}>
          Feel free to reach out via the contact form or by email,
          <a className={`${email} mailtoui`} href='mailto:ceejaymar@gmail.com'>ceejaymar@gmail.com</a>
        </p>
      </div>
    </section>
  );
};

export default withStyles(styles)(Contact);
