import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/aboutStyles.js'

const About = ({ classes }) => {
  const {
    about,
    aboutLeft,
    spinner,
    aboutRight,
    aboutHeader,
    image,
    aboutMe
  } = classes;

  return (
    <section className={about}>
      <div className={aboutLeft}>
        <div className={spinner}>
          <img className={image} src={require('../styles/carlos3.png')} alt="Illustration of Carlos"/>
        </div>
      </div>
      <div className={aboutRight}>
        <h1 className={aboutHeader}>Hi, I'm Carlos</h1>
        <p className={aboutMe}>Full stack web developer</p>
        <p className={aboutMe}>Design Enthusiast</p>
        <p className={aboutMe}>Based in Brooklyn</p>
      </div>
    </section>
  );
};

export default withStyles(styles)(About);
