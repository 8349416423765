import React from 'react';
import { withStyles } from '@material-ui/styles';

import { projectsInfo } from '../content';

import styles from '../styles/projectsStyles';

const Projects = ({ classes }) => {
  const {
    projects,
    projectsHeader,
    projectList,
    projectCont,
    image,
    overlay,
    description,
    linkGroup,
    github,
    live
  } = classes;

  return (
    <section className={projects} id='projects'>
      <h2 className={projectsHeader}>Projects</h2>
      <div className={projectList}>
        {
          // todo: reupload optimized images
          projectsInfo.map((project, index) => (
            <div className={projectCont} key={index}>
              <img className={image} src={project.img} alt={project.name} />
              <div className={overlay}>
                <p className={description}>{project.desc}</p>

                <div className={linkGroup}>
                  <a
                    className={github}
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    button
                  </a>
                  <a
                    className={live}
                    href={project.live}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    button
                  </a>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  );
};

export default withStyles(styles)(Projects);
