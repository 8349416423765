import colors from "./colors";
import sizes from "./sizes";

const styles = {
  about: {
    display: "flex",
    height: "90vh",
    [sizes.down("lg")]: {
      flexDirection: "column",
      height: "auto",
    },
  },
  aboutLeft: {
    width: "50%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [sizes.down("lg")]: {
      width: "100%",
      padding: "40px 0",
      backgroundColor: colors.red,
    },
    [sizes.down("sm")]: {
      padding: "20px 0 0 0",
    },
  },
  spinner: {
    boxSizing: "border-box",
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      top: "-1px",
      left: "0",
      width: "100%",
      height: "100%",
      borderTop: `7px solid ${colors.red}`,
      borderRight: `7px solid ${colors.blue}`,
      borderBottom: `7px solid ${colors.purple}`,
      borderLeft: `7px solid ${colors.yellow}`,
      borderRadius: "50%",
      // animation: '$spin 5s infinite linear',
      boxSizing: "border-box",
      boxShadow: "0 20px 40px rgba(0, 0, 0, 0.25)",
      [sizes.down("lg")]: {
        borderTop: `7px solid ${colors.green}`,
      },
    },
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  image: {
    height: "450px",
    width: "450px",
    borderRadius: "50%",
    position: "relative",
    [sizes.down("lg")]: {
      height: "300px",
      width: "300px",
    },
  },
  aboutRight: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.red,
    color: colors.white,
    fontSize: "3rem",
    [sizes.down("lg")]: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-around",
      fontSize: "2rem",
    },
    [sizes.down("md")]: {
      fontSize: "1.8rem",
    },
    [sizes.down("sm")]: {
      flexDirection: "column",
    },
    [sizes.down("xs")]: {
      padding: "0 0 40px 0",
    },
  },
  aboutHeader: {
    margin: "20px 0 10px 0",
    letterSpacing: "-2px",
    [sizes.down("lg")]: {
      width: "20%",
      textAlign: "center",
    },
    [sizes.down("sm")]: {
      width: "100%",
      textAlign: "center",
    },
    [sizes.down("xs")]: {
      width: "100%",
    },
  },
  aboutMe: {
    [sizes.down("lg")]: {
      width: "20%",
      textAlign: "center",
      margin: "1.5rem 0 1.5rem 0",
    },
    [sizes.down("sm")]: {
      flex: "1",
      width: "50%",
    },
    [sizes.down("xs")]: {
      width: "50%",
    },
  },
};

export default styles;
