import React from "react";
import ReactGA from "react-ga";

import Navbar from "./components/Navbar";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import "./App.css";

function initializeAnalytics() {
  ReactGA.initialize("UA-151224771-1");
  ReactGA.pageview("/home");
}

function App() {
  initializeAnalytics();

  return (
    <div className="App">
      <Navbar />
      <About />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
