import bg from "./bg.svg";
import colors from "./colors";
import sizes from "./sizes";

const styles = {
  message: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
    backgroundColor: colors.red,
    backgroundImage: `url(${bg})`,
    fontSize: "3rem",
    color: "white",
    fontWeight: "600",
    [sizes.down("lg")]: {
      height: "200px",
      fontSize: "2.5rem",
    },
    [sizes.down("xs")]: {
      height: "200px",
      fontSize: "2rem",
      textAlign: "center",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    padding: "0 40px",
    color: "white",
    backgroundColor: colors.darkGrey,
  },
  [sizes.down("sm")]: {
    padding: "0 20px",
  },
};

export default styles;
